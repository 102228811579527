@keyframes bg {
  0% {
    background: linear-gradient(
      to right,
      #111111 20%,
      #242424 50%,
      #111111 80%
    );
  }
  50% {
    background: linear-gradient(
      to right,
      #111111 30%,
      #242424 70%,
      #111111 100%
    );
  }
  75% {
    background: linear-gradient(
      to right,
      #111111 20%,
      #242424 50%,
      #111111 80%
    );
  }
  100% {
    background: linear-gradient(to right, #111111 8%, #242424 18%, #111111 33%);
  }
}

@keyframes load {
  from {
    left: -50%;
  }
  to {
    left: 50%;
  }
}

@keyframes scaler {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes bg {
  0% {
    background-position: top;
  }
  50% {
    background-position: bottom;
  }
  100% {
    background-position: top;
  }
}

@keyframes scroller {
  0% {
    top: 0%;
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    top: 78%;
    opacity: 0.5;
  }
}

@keyframes LinearBackground {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  90% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes init {
  0% {
    right: -20%;
  }
}

@keyframes out {
  100% {
    right: -20%;
  }
}

@keyframes SmallBouncer {
  0% {
    transform: scale(0.2);
  }
  50% {
    transform: scale(0.25);
  }
  100% {
    transform: scale(0.2);
  }
}

@keyframes PhoneBouncer {
  0% {
    transform: scale(0.7);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(0.7);
  }
}

@keyframes BigBouncer {
  0% {
    transform: scale(0.1);
  }
  50% {
    transform: scale(0.2);
  }
  100% {
    transform: scale(0.1);
  }
}

@keyframes quickSpinIn {
  100% {
    transform: rotate(180deg);
  }
}

@keyframes quickSpinOut {
  100% {
    transform: rotate(-360deg);
  }
}

@keyframes wider {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes opacify {
    0%{
        opacity: 0;
        bottom: -100%;
    }
    100%{
        opacity: 1;
        bottom: 0;
    }
}
@keyframes hider {
    0%{
        opacity: 0;
        bottom: -50%;
    }
    100%{
        opacity: 0;
        bottom: -50%;
    }
}

