%btn{
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: 5%;
  right: 2%;
  border-radius: 50%;
  z-index: 2;
  background: rgba(0, 0, 0, 0.255);
  backdrop-filter: blur(10px);
  border: 1px solid $muted-text;
  color: $muted-text;
  display: none;
  justify-content: center;
  align-items: center;
  transition: $trans;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  -webkit-backdrop-filter: blur(10px);
  @media screen and (max-width:800px) {
    display: none;
  }
  &:hover{
    color: $secondary;
    border: 1px solid rgba(0, 0, 0, .5);
    background: rgba(255, 255, 255, 0.2);
  }
}

.vitem,.vitem-2,.auto-loader{
  margin: 0% 0.2%;
  transition: 0.5s;
  position: relative;
  cursor: pointer;
  background: url('../../assets/images/tape.png')no-repeat;
  background-size: cover;
  background-blend-mode: darken;
  background: rgba(34, 34, 34, 0.5);
  border-radius: $border-radius;
  overflow: hidden;
  width: 100%;
  height: 200px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);

  @media screen and (max-width:4200px){
    height: 300px;
  }  
  
  @media screen and (max-width:2900px){
    height: 180px;
  }

  @media screen and (max-width:2000px){
    height: 170px;
  }

  @media screen and (max-width:1651px){
    height: 160px;
  }

  @media screen  and (max-width:1400px){
    height: 150px;
  }

  
  @media screen and (max-width:1025px){
    margin: 0% 2px;
  }

  @media screen  and (max-width:1024px){
    width: 200px;
    height: 120px;
  }

  @media screen  and (max-width:700px){
    width: 160px;
    height: 100px;
    box-shadow: 0px 0px 10px #111;
  }

  @media screen  and (max-width:700px){
    width: 140px;
    height: 90px;
  }

  video{
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    box-shadow: 0px -0px 2px 0px rgba(128, 128, 128, 0.5);
    object-fit: cover;
  }


  img{
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top left;
    z-index: 2;
  }

  span{
    @extend %btn;
    bottom: 3%;
    padding: 1em 1em 1em 1.2em;
  }

  .progress-container{
    position: absolute;
    bottom: 0;
    left: -1%;
    z-index: 1;
    height: 3%;
    width: 102%;
    background: rgba(126, 126, 126, 0.3);
    backdrop-filter: blur(5px);
    .progress{
      position: absolute;
      height: 100%;
      left: 1%;
      background: $primary;
    }
  }

  &:hover{
    box-shadow: 0px 0px 20px #111;
    
    span{
      display: flex;
    }
    -webkit-transition-delay: 0.5s;
    -moz-transition-delay: 0.5s;
    -ms-transition-delay: 0.5s;
    -o-transition-delay: 0.5s;
    transition-delay: 0.5s;
  }
} 

.vitem:hover{
  @media screen and (min-width:1024px) {
    z-index: 5;
    border: none;
    -webkit-transition-delay: 0.5s;
    -moz-transition-delay: 0.5s;
    -ms-transition-delay: 0.5s;
    -o-transition-delay: 0.5s;
    transition-delay: 1s;
  }
}

.hasScaler{
  transform: translateY(-8%) scale(1.3);
}

.noScaler{
  transform: translateY(0) scale(1);
}

.hide{
  display: none;
}

.show{
  display: initial;
}

.notificaation_text{
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);
  color: lightgrey;
  position: absolute;
  bottom: -6%;
  left: 2%;
  z-index: 20;
  font-size: 1em;
  padding: .2em .5em;
  border-radius: .2em;
  font-weight: 500;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.6);
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}


.vitem-2,.longCarousel-loader{
  width: 100%;
  height: 400px;
  
  @media screen and (max-width:4100px){
    height: 500px;
  }
  
  @media screen and (max-width:2900px){
    height: 320px;
  }
  

  @media screen  and (max-width:1400px){
    height: 270px;
  }

  @media screen  and (max-width:1024px){
    width: 180px;
    height: 200px;
  }

  @media screen and (max-width:700px) {
    width: 120px;
    height: 150px;
  }

  @media screen and (max-width:500px) {
    width: 100px;
    height: 150px;
  }

  span{
    padding: 1em 1em 1em 1.2em;
    bottom: 1%;
  }
  

  &:hover{
    video{
      display: initial;
      object-position: bottom left;
    }
    img{
      display: none;
    }
  }
  
  video{
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: none;
  }
  img{
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top left;
  }
  .progress-container{
    height:1.5%;
  }
  .notificaation_text{
    bottom: -3%;
  }
}

.longCarousel-loader,.auto-loader{
  position: relative;
  background: rgba(34, 34, 34, 0.5);
  margin: 0% 0.2%;
  border-radius: $border-radius;
  overflow: hidden;
  &::before{
      content: " ";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      background: linear-gradient(120deg, transparent 30%, #aeaeae27 50%, transparent 70%);
      animation: load 1.5s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
  }
}

.longCarousel-loader{
  &::before{
    content: " ";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0%;
    background: linear-gradient(100deg, transparent 30%, #aeaeae27 50%, transparent 70%);
    animation: load 1.5s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
}
}
