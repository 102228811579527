@import '../variables';

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.linear-background {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #000000;
  background: linear-gradient(to right, #111111 8%, #242424 18%, #111111 33%);
  background-size: 1000px 104px;
  height: 338px;
  position: relative;
  overflow: hidden;
}


.Big-loader{
  height: 80%;
  width: 80%;
  z-index: 10;
  margin: auto;
  margin-top: 2%;
  padding: 20px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width:700px){
      height: 100%;
      width: 90%;
      padding: 0;
      margin-top: 10%;
  }

  div{
      width: 100%;
      height: 50px;
      border-radius: 2px;
      margin: 0.5% auto;
      background: rgba(34, 34, 34, 0.5);
      position: relative;
      overflow: hidden;
      backdrop-filter: blur(50px);
      &::before{
          content: " ";
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          background: linear-gradient(120deg, transparent 30%, #aeaeae43 50%, transparent 70%);
          animation: load 1.5s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
      }
      @media screen and (max-width:700px) {
          margin: 2% auto;
          opacity: 0.8;
      }
  }

  .avatar{
      position: relative;
      width:40%;
      height: 50%;
      border-radius: $border-radius;
      @media screen and (max-width:700px) {
          width: 120px;
          height: 120px;
          border-radius: 50%;
      }
  }

  .names{
    border-radius: $border-radius;
    width: 60%;
  }
  .xns{
    border-radius: $border-radius;
    width: 30%;
  }
}

.circle-loader{
  position: relative;
  width: 100%;
  height: 250px;
  background: rgba(34, 34, 34, 0.5);
  margin: 0% 0.2%;
  border-radius: $border-radius;
  overflow: hidden;

  @media screen and (max-width:3000px){
    height: 190px;
  }

  @media screen and (max-width:1601px){
    height: 170px;
  }

  @media screen and (max-width:1400px){
    height: 150px;
  }

  @media screen and (max-width:1025px){
    margin: 0% 2px;
  }

  @media screen and (max-width:1200px){
    width: 210px;
    height: 120px;
  }

  @media screen  and (max-width:1024px){
    width: 200px;
    height: 120px;
  }

  @media screen  and (max-width:700px){
    width: 160px;
    height: 100px;
  }

  @media screen  and (max-width:700px){
    width: 140px;
    height: 90px;
  }

  &::before{
      content: " ";
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      background: linear-gradient(120deg, transparent 30%, #aeaeae27 50%, transparent 70%);
      animation: load 1.5s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
  }
}

.circle-loader{
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 3px solid #333;
  @media screen  and (max-width:1400px){
    width: 180px;
    height: 180px;
  }
  @media screen  and (max-width:1200px){
    width: 150px;
    height: 150px;
  }
  @media screen  and (max-width:700px){
    width: 130px;
    height: 130px;
  }

}

.preview-loader{
  height: 60px;
  width: 100%;
  border-radius: .5em;
  display: flex;
  justify-content: space-around;
  align-items: center;
  .avatar{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: rgba(34, 34, 34, 1);
    box-shadow: 0px 0px 10px #111; 
    position: relative;
    overflow: hidden;
     &::before{
        content: " ";
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        background: linear-gradient(120deg, transparent 30%, #aeaeae27 50%, transparent 70%);
        animation: load 1.5s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
    }
  }
  .content{
    flex: 0.9;
    height: 80%;
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    .title{
      background: rgba(34, 34, 34,1);
      box-shadow: 0px 0px 10px #111;
      height: 25%;
      border-radius: 2em; 
      position: relative;
      overflow: hidden;
       &::before{
          content: " ";
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          background: linear-gradient(120deg, transparent 30%, #aeaeae27 50%, transparent 70%);
          animation: load 1.5s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
      }
    }
    .subs{
      height: 25%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #333;
      span{
        flex: 0.4;
        height: 100%;
        background: rgba(34, 34, 34, 1);
        box-shadow: 0px 0px 10px #111;
        border-radius: 2em; 
        position: relative;
        overflow: hidden;
         &::before{
            content: " ";
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            background: linear-gradient(120deg, transparent 30%, #cecece27 50%, transparent 70%);
            animation: load 1.5s cubic-bezier(0.4, 0.0, 0.2, 1) infinite;
        }
      }
    }
  }
}
