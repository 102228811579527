.chat-container{
    position: fixed;
    right: 1%;
    bottom: 4%;
    z-index: 5;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: column;

    .messenger{
        flex-grow: .95;
        min-height: 500px;
        min-width: 500px;
        margin-bottom: 3%;
        backdrop-filter: blur(30px);
        background: linear-gradient(transparent,black);
        border-radius: 2em;
        border-bottom-right-radius: 0;
        border: 1px solid #2a2a2a;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        animation: taller .5s ease-out;
        position: relative;
        transform: translateX(-1%);
        -webkit-backdrop-filter: blur(10px);
        @media screen and (min-width:2500px) {
            width: 650px;
            max-height: 700px;
            height: 650px;
        }
        @media screen and (max-width:700px) {
            width: 100%;
            min-width: 90%;
            min-height: 90%;
            height: 96vh;
            margin: 3% auto;
            transform: translateX(2%);
        }
        .messages{
            flex-grow: 1;
            overflow-y: scroll;
            padding: 1em;
            padding-bottom: 0;
            &::-webkit-scrollbar{
                display: none;
            }

            .msg-placeholder{
                color: #555;
                width: 50%;
                height: 50%;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%);
            }

            .message{
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 80%;

                .bot-avatar,.admin-avatar,.user-avatar{
                    width: 70px;
                    background: #333;
                    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
                    border-radius: 50%;
                    @media screen and (max-width:1600px) {
                        width: 50px;
                    }
                    img{
                        width: 100%;
                    }
                }

                .bot-avatar,.admin-avatar{
                    margin-right: .5em;
                    transform: translateY(25%);
                    border: 1px solid #333;
                }

                .content-text{
                    border-radius: 1em;
                    background: #444;
                    padding: .5em 1.5em;
                    @media screen and (max-width:700px) {
                        font-size: 13px;
                    }
                }

                .admin-avatar{
                    background: none;
                    border: none;
                    transform: none;
                    position: relative;
                }

                .user-avatar{
                    width: 60px;
                    padding: .7em 1em;
                    margin-left: .5em;
                    background: $primary;
                    border: 1px solid #222;
                    
                    @media screen and (max-width:1600px) {
                        padding: 50px;
                        padding: .4em .7em;
                    }
                    @media screen and (max-width:700px) {
                        width: 50px;
                    }
                }
            }

            
            .time{
                color: grey;
                transform: translate(5%,-60%);
            }

            .user-message{
                flex-direction: row-reverse;
                justify-content: flex-start;
                margin-left: auto;
                .content-text{
                    border-bottom-right-radius: 0;
                }
            }

            .admin-message{
                .content-body{
                    margin-right: auto;
                    text-align: right;
                    .content-text{
                        border-bottom-left-radius: 0;
                        background: $primary;
                    }
                    .time{
                        transform: translate(-5%,-60%);
                    }
                }
            }

            .bot{
                justify-content: flex-start;
                .content-text{
                    background: $primary;
                    border-bottom-left-radius: 0;
                }
            }
        }

        .divider{
            margin: 2% 0;
            border: .1px solid #111;
        }

        .input-field{
            display: flex;
            justify-content: space-between;
            align-items:center;
            width: 90%;
            margin: 0 auto;
            padding: 0 1em 1em 1em;
            input{
                flex-grow: .9;
                outline: none;
                border: none;
                background: transparent;
                color: whitesmoke;
            }
            .icon{
                color: #555;
            }
        }
    }

    .opener{
        display: flex;
        align-items: center;
        cursor: pointer;
        .opener-text{
            background: $primary;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: .5em 1em ;
            border-radius: 2em;
            margin-right: 1em;
            border-bottom-right-radius: 0;
            animation: show 1s cubic-bezier(.48,0,.36,1.48);
            position: relative;
            display: none;
            @media screen and (min-width:2500px) {
                font-size: 20px;
            }
        }
        .model-toggle{
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
            backdrop-filter: blur(10px);
            color: $primary;
            padding: .8em;
            border-radius: 50%;
            position: relative;
            overflow: hidden;
            -webkit-backdrop-filter: blur(10px);
            @media screen and (max-width:700px) {
                padding: .4em;
                background: radial-gradient(rgba(0, 0, 0, 0.2) 50%,transparent);
            }
        }

        &:hover{
            .opener-text{
                display: initial;
            }
        }

        &:hover{
            .opener-text{
                display: initial;
            }
        }
    }

    .loader-wrapper{
        position: absolute;
        left: 50%;
        top:50%;
        transform: translate(-50%,-50%);
        .loader{
            animation: spin 1s linear infinite;
            background: transparent;
        }
    }

    

    @media screen and (max-width:700px) {
        right: 3%;
        bottom: 3%;
        max-width: 98%;
        max-height: 98vh;
    }
}

@keyframes show {
    0%{
        opacity: 0;
        left: 30%;
    }
    100%{
        opacity: 1;
        left: 0;
    }
}

@keyframes identifier {
    0%{
        opacity: 1;
        left: 0;
    }
    100%{
        opacity: 0;
        left: 50%;
    }
}

@keyframes show {
    0%{
        opacity: 0;
        left: 30%;
    }
    100%{
        opacity: 1;
        left: 0;
    }
}

@keyframes identifier {
    0%{
        opacity: 1;
        left: 0;
    }
    100%{
        opacity: 0;
        left: 50%;
    }
}

@keyframes taller {
    0%{
        min-height: 0px;
        opacity: 0;
    }
    100%{
        min-height: 500px;
        opacity: 1;
    }
}

@keyframes smaller {
    0%{
        min-height: 500px;
        opacity: 1;
    }
    100%{
        min-height: 0px;
        opacity: 0;
    }
}
