// =============== VIDEO HERO ================
.Featured{
  position: relative;
  margin-bottom: -8%;
  height: 70vh;
  z-index: 0;
  width: 100%;
  background: $secondary;

  button{
    backdrop-filter: blur(20px);
  }

  @media screen and (max-width:1024px){
    margin-bottom: -30%;
  }

  @media screen and (max-width:700px){
    margin-bottom: 0%;
    background: transparent;
    button{
      background: whitesmoke;
      color: $secondary;
      font-weight: bold;
    }
    img{
      width: 100%;
      object-fit: cover;
      object-position: bottom;
    }
  }

  .video-content{
    display: none;
    text-transform: capitalize;
    @media screen and (max-width:700px) {
      display: block;
    }
  }

  .Featured-Player{
    z-index: 0;
  }

  .Featured-overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
    position: relative;
    background: linear-gradient( -150deg, rgba(0, 0, 0, 0) ,rgba(0, 0, 0, 0.0) ,#1a1a1a);
    &::before{
      content: " ";
      position: absolute;
      width: 100%;
      height: 100%;
      background: linear-gradient( rgba(0, 0, 0, 0) ,rgba(0, 0, 0, 0) ,$secondary);
      @media screen and (max-width:700px){
        background: transparent;
      }
    }
    @media screen and (max-width:700px){
      z-index: 0;
      background: linear-gradient( rgba(0, 0, 0, 0)  ,$secondary);
      background: transparent;
      display: none;
    }
  }

  .Featured-content{
    position: absolute;
    top:30%;
    left: 50%;
    transform: translate(-50%,-20%);
    z-index: 2;
    width: 97%;
    height: 60%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    @media screen and (max-width:700px){
      justify-content: center;
      align-items: center;
      bottom: 0;
      transform: translate(-50%,20%);
      &::before{
        content: " ";
        position: absolute;
        bottom: 0;
        background: linear-gradient(rgba(0, 0, 0, 0),#1a1a1aab,$secondary 90%);
        width: 120%;
        height: 50%;
        z-index: -1;
      }
    }
    div{
      flex: 0.2;
      width:40%;
      position: relative;
      @media screen and (max-width:700px){
        width:70%;
      }
      img{
        position: absolute;
        width:100%;
        height: 100%;
        @media screen and (max-width:700px){
          opacity: 0;
        }
      }
      &:first-child{
        flex: 0.6;
        width: 20%;
        @media screen and (max-width:1500px){
          width: 30%;
        }
        @media screen and (max-width:700px){
          width: 40%;
        }
      }
      &:last-child{
        @media screen and (max-width:700px){
          width: auto;
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 2% 0;
        }
      }
    }
    @media screen and (max-width:700px){
      top: 30%;
    }
  }
}

header {
  position        : relative;
  background-color: black;
  height          : 75vh;
  min-height      : 25rem;
  width           : 100%;
  overflow        : hidden;
}

header video {
  position         : absolute;
  top              : 50%;
  left             : 50%;
  min-width        : 100%;
  min-height       : 100%;
  width            : auto;
  height           : auto;
  z-index          : 0;
  -ms-transform    : translateX(-50%) translateY(-50%);
  -moz-transform   : translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform        : translateX(-50%) translateY(-50%);
}

header .container {
  position: relative;
  z-index : 2;
}

header .overlay {
  position        : absolute;
  height          : 100%;
  width           : 100%;
  background-color: black;
  opacity         : 0.3;
  z-index         : 1;
}

.parent{
  @media screen and (max-width:700px){
  margin-top: -20%;
  }
}