input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-text-size-adjust: 1.2em;
  -webkit-text-fill-color: $secondary*2;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
  @media screen and (max-width: 800px) {
    -webkit-text-fill-color: $text;
  }
}

%forms {
  display: flex;
  flex-direction: column;
  padding: 1em 3em;
  border-radius: $border-radius;
  transition: $trans;
  transform: translateX(30%);
  @media screen and (max-width: 800px) {
    transform: translateY(-8%);
    padding: 1em 0;
    width: 75%;
    backdrop-filter: blur(20px);
  }
  
  h1 {
    font-weight: bold;
    margin: 0.5em 0;
    font-size: 50px;
    color: $text;
    filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.3));
    @media screen and (max-width: 1400px) {
      margin: 5% 0;
    }
    @media screen and (max-width:800px) {
      text-align: center;
      font-family: "Great Vibes", cursive ;
      background: transparent;
      margin: 10% 0;
    }
    span {
      margin: 0 0.5em;
    }
  }

  .input-field {
    padding: .8em 1.2em;
    background: $input-bg;
    margin: 3% 0;
    border-radius: $border-radius;
    display: flex;
    justify-content: space-between;
    align-items: center;
    opacity: .9;

    @media screen and (max-width: 1400px) {
      padding: 0.8em 1.3em;
      margin: 1% 0;
    }

    @media screen and (max-width: 800px) {
      padding: 0.5em 1em;
      box-shadow: inset 0px 0px 2px black;
      background: #cccccc1c;
      margin: 1.5% 0;
      border: 1px solid $muted-text;
    }

    input {
      border: none;
      color: $secondary*2;
      outline: none;
      font-weight: 600;
      background-color: transparent;
      flex: 1;
      @media screen and (max-width: 800px) {
        color: $text;
      }
    }
    i {
      color: #666;
      cursor: pointer;
      animation: scaler 1s ease-out;
      &:hover {
        color: #444;
      }
    }
  }

  .input-check {
    display: flex;
    flex-direction: row;

    input{
      margin: 7px;
    }
  }
  
  p {
    color: $muted-text;
  }

  a {
    transition: $trans;
    color: $text;
  }

  .error {
    color: $primary;
    font-weight: 700;
    font-size: 1.2em;
    margin: 0.5em auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    transition: $trans;
    animation: fader 1s forwards;
    letter-spacing: 1px;
    span {
      display: none
    }
  }

  button {
    @include btn();
    margin: 1em 0 1.5em 0;
    i {
      margin: 0 1em;
      font-size: 15px;
      animation: spin 2s linear infinite;
    }
    @media screen and (max-width: 1400px) {
      margin: 0.8em 0 1.2em 0;
    }

    @media screen and (max-width: 800px) {
      width: 50%;
      margin: 1em auto 1.5em auto;
      padding: 1em 0;
    }

    @media screen and (max-width: 500px) {
      width: 40%;
      margin-bottom: 10%;
    }
  }
}

.Auth {
  height: 100vh;
  overflow: hidden;
  @media screen and (max-width: 800px) {
    min-height: 110vh;
    height: auto;
  }
  .parent {
    display: flex;
    justify-content: space-between;
    width: 85%;
    max-width: 1600px;
    margin: 0 auto;
    align-items: center;
    position: relative;
    &::after {
      content: " ";
      position: absolute;
      width: 300px;
      height: 300px;
      border-radius: 50%;
      background: linear-gradient(transparent, $primary 200%);
      backdrop-filter: blur(20px);
      transform: rotate(130deg);
      top: 0;
      z-index: -1;
      &:hover {
        transform: none;
      }
      @media screen and (max-width: 800px) {
        width: 200px;
        top: auto;
        height: 200px;
      }
    }

    @media screen and (min-width: 3001px) {
      width: 60%;
    }

    @media screen and (max-width: 3000px) and (min-width: 2000px) {
      width: 80%;
    }

    @media screen and (max-width: 800px) {
      width: 100%;
      margin: 0;
      justify-content: center;
      align-items: center;
      flex-direction: column-reverse;
      position: relative;
    }

    form {
      @extend %forms;
      flex: 0.25;

      @media screen and (min-width: 800px) and (max-width: 1600px) {
        flex: 0.35;
        transform: translateX(10%);
      }

      .forgot {
        color: $muted-text;
        transition: $trans;
        &:hover {
          color: $text;
          text-decoration: none;
        }
        @media screen and (max-width: 800px) {
          font-size: 13px;
        }
      }
      .match-error {
        margin: 0em 0 1em 0.5em;
        color: $primary/1.5;
        letter-spacing: 1px;
        transition: $trans;
      }
    }

    section {
      flex: 0.5;
      position: relative;
      margin-top: 5%;
      @media screen and (max-width: 800px) {
        display: flex;
        justify-content: center;
      }
      img {
        width: 90%;
        padding-right: 0.5em;
        position: relative;
        left: 10%;
        z-index: 1;
        filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
        @media screen and (max-width: 800px) {
          left: 0%;
          width: 55%;
          height: 50%;
          margin: 0 auto;
        }
      }
      &::before {
        content: " ";
        background: linear-gradient($primary 80%, $secondary/3);
        position: absolute;
        width: 80%;
        height: 500%;
        bottom: -15%;
        left: 25%;
        z-index: 0;
        border-radius: $border-radius;
        @media screen and (max-width: 800px) {
          background: linear-gradient($primary 0%, transparent 90%);
          width: 70%;
          height: 90%;
          left: 50%;
          transform: translate(-50%, 0);
          bottom: -30%;
        }
      }
    }
  }
}
