%optionInfo{
    @include flexed();
    width: 85%;
    max-width: 1300px;
    margin: 2em auto;
    letter-spacing: 1px;

    @media screen and (max-width:700px) {
        flex-direction: column-reverse;
    }

    h1{
        font-size: 60px;
        font-weight: bold;
        font-family: "Sarala-Bold", sans-serif !important;

        @media screen and (max-width:1600px) {
            font-size: 50px;
        }
        @media screen and (max-width:1024px) {
            font-size: 30px;
        }

        @media screen and (max-width:700px) {
            font-size: 25px;
            letter-spacing: 0;
        }
    }
    p{
        font-size: 30px;
        margin: 3% 0;
        @media screen and (max-width:1600px) {
            font-size: 25px;
        }
        @media screen and (max-width:1024px) {
            font-size: 18px;
        }

        @media screen and (max-width:700px) {
            font-size: 15px;
        }
    }
    button{
        @include btn();
        @media screen and (max-width:800px) {
            width: 40%;
            padding: 1em 0;
        }
    }

    img{
        width: 120%;
        filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));

        @media screen and (max-width:1400px) {
            width: 100%;
        }

        @media screen and (max-width:1024px) {
            width: 90%;
        }

        @media screen and (max-width:700px) {
            width: 80%;
        }
    }
}

.landing{
    overflow: hidden;
    .zero{
        width: 100%;
        max-width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        section{
            background: url(../../assets/images/posterRed.jpg);
            max-width: 1600px;
            width: 85%;
            height: 600px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            text-align: center;
            animation: bg 20s ease-in-out infinite;
            
            h1{
                font-family: "Sarala-Bold", sans-serif !important;
            }

            div{
                z-index: 1;
            }
            
            @media screen and (min-width:3001px) {
                width: 60%;
            }

            @media screen and (max-width:3000px) and (min-width:2000px) {
                width: 80%;
                transform: translate(0%,10%);
            }
            
            @media screen and (max-width:800px) {
                width: 90%;
                height: 500px;
                h1{
                    font-size: 25px;
                    font-weight: bolder;
                }
            }

            &::before{
                content: " ";
                position: absolute;
                width: 105%;
                height: 105%;
                left: -1%;
                top: -1%;
                background: radial-gradient(#1a1a1acd,$secondary);
                z-index: 0;

            }
            &::after{
                content: " ";
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                background: linear-gradient($secondary, transparent, $secondary);
                z-index: 0;
            }
            h3{
                margin: 5% 0;
                font-family: "Sarala", sans-serif !important;
                @media screen and (max-width:800px) {
                    font-size: 25px;
                }
            }
            button{
                @include btn();
                margin: 0 auto;
                @media screen and (max-width:800px) {
                    width: 40%;
                    padding: 1em 0;
                }
            }
        }
    }
    .one{
        @extend %optionInfo;
        .left{
            position: relative;
            @media screen and (max-width:1600px) {
                left: -0%;
            }
            @media screen and (max-width:1024px) {
                left: -3%;
            }
            @media screen and (max-width:700px) {
                left: -5%;
            }
        }
        img{
            position: relative;
            left: -30%;
            top: 10%;
            transform: translate(0%,10%);
            @media screen and (max-width:1600px) {
                transform: translate(0%,0%);
                left: -22%;
            }
            @media screen and (max-width:1400px) {
                transform: translate(0%,0%);
                left: -1%;
            }
            @media screen and (max-width:1024px) {
                transform: translate(0%,10%);
                left: 10%;
            }
            @media screen and (max-width:700px) {
                transform: translate(14%,-5%);
                left: 10%;
            }
        }
    }
    .two{
        @extend %optionInfo;
        flex-direction: row-reverse;
        @media screen and (min-width:701px) and (max-width:800px) {
            margin-top: 5em;
        }
        @media screen and (max-width:700px) {
            flex-direction: column-reverse;
        }
        img{
            @media screen and (max-width:700px) {
                width: 100%;
            }
        }
        .left{
            position: relative;
            left: 5%;
            @media screen and (min-width:701px) and (max-width:800px) {
                width: 70%;
            }
            @media screen and (max-width:700px) {
                margin-top: 3em;
                left: -2%;
            }
            &::before{
                content:" ";
                position: absolute;
                background: linear-gradient($primary 10%,$secondary/2);
                width: 90%;
                height: 450%;
                z-index: -1;
                left: -5%;
                bottom: -10%;
                border-radius: $border-radius;
                @media screen and (max-width:1600px) {
                    width:95%;
                    left: -5%;
                }
                @media screen and (max-width:800px) {
                    width: 100%;
                    left: -5%;
                }
                @media screen and (max-width:700px) {
                    width: 52%;
                    left: 58%;
                    background: linear-gradient($primary 20%,transparent);
                    bottom: 0%;
                    height: 1000%;
                }
            }
        }
    }
    .three{
        @extend %optionInfo;
        margin: 10em auto 5em auto;
        @media screen and (max-width:700px) {
            flex-direction: column;
            margin: 5em auto 0em auto ;
            text-align: center;
        }
        img{
            width: 150%;
            position: relative;
            left:-60%;
            @media screen and (max-width:800px) {
                left: -30%;
            }
            @media screen and (max-width:700px) {
                width: 100%;
                left: 0;
                margin-top: 2em;
            }
        }
    }
    .four{
        @extend %optionInfo;
        flex-direction: column;
        margin: 10em auto;
        width: 70%;
        @media screen and (max-width:700px) {
            margin: 5em auto;
            width: 80%;
            h1{
                font-size: 20px;
            }
        }
        .accordion{
            margin: 2em auto;
            width: 100%;
            @media screen and (max-width:800px) {
                width: 95%;
            }
            @media screen and (max-width:700px) {
                width: 100%;
                margin: 1em auto;
                h4{
                    font-size: 18px;
                }
            }
            span{
                cursor: pointer;
            }
        }
    }
}