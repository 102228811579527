.HeaderTwo{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 85%;
    max-width: 1600px;
    margin: 0 auto;
    padding: .5em 0;
    position: relative;
    z-index: 1;

    @media screen and (min-width:3001px) {
        width: 60%;
    }
    @media screen and (max-width:3000px) and (min-width:2000px) {
        width: 80%;
        transform: translate(0%,10%);
    }
    
    @media screen and (max-width:800px) {
        width: 90%;
    }

    .logo{
        background: none;
        color: $primary;
        text-transform: uppercase;
        font-family: 'Sarala-Bold',sans-serif !important;
        font-size: 35px;
        @media screen and (max-width:800px) {
            font-size: 25px;
        }
    }

    a{
        color:$text;
        font-weight: 500;
        font-size: 20px;
        padding: .2em .5em;
        border-radius: $border-radius;
        transition: $trans;
        background: $primary;
        font-family: $fontFamily;
        @media screen and (max-width:1600px) {
            font-size: 17px;
        }
        @media screen and (max-width:1024px) {
            margin-right: 2.5%;
        }
        @media screen and (max-width:800px) {
            font-size:  15px;
            background: $primary;
        }
        &:hover{
            background: $muted-text;
            text-decoration: none;
        }
    }
}