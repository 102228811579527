.vsection {
  position: relative;
  height: 70vh;
  display: flex;
}
.vscreen {
  flex-basis: 0;
  position: relative;
  flex-grow: 3;
  display: flex;
  overflow: hidden;
  transition: 1s;
  // background-color: red;
  background-repeat: no-repeat;
  background-position: center;
  &:hover {
    flex-grow: 5;
  }
}
@include media-breakpoint-down(md) {
  .vsection {
    height: 30vh;
  }
  .vsection :hover {
    flex-grow: 3;
  }
}
@include media-breakpoint-down(sm) {
  .vsection {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    height: 90vh;
  }
  .vsection :hover {
    flex-grow: 3;
  }
}
