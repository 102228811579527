*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: linear-gradient($secondary 20%,black ), $secondary;
  color: $text !important;
  @media screen and (max-width:800px) {
    background: linear-gradient($secondary 20%,black );
  }
}

.text-lg {
  font-size: $font-bg;
}

.text-xl {
  font-size: $font-bg;
  line-height: 1.2;

  @include media-breakpoint-up(lg) {
    font-size: $font-xl;
  }
}

.nohover:hover {
  background: transparent !important;
  color: white !important;
}

video:focus {
  outline: none;
}

.animation-container {
  position: relative;
  min-height: 300px;

  @include media-breakpoint-up(lg) {
    min-height: 400px;
  }

  .ac1 {
    background: url("../assets/images/tv.png") center center no-repeat;
    box-sizing: border-box;
    background-size: contain;
    position: absolute;
    padding: 11.9% 15.5% 14.8%;
    position: absolute;
    top: 0;
    width: 92%;
    height: 92%;
  }

  .ac2 {
    background: url("../assets/images/device-pile.png") center center no-repeat;
    box-sizing: border-box;
    background-size: contain;
    position: absolute;
    padding: 0% 18% 18%;
    top: 0;
    width: 80%;
    height: 80%;
  }
}

.navbar-dark {
  transition: background-color 200ms linear;
}

.hSection {
  min-height: 90vh !important;
}

.hFullSection {
  min-height: 100vh !important;
}

@media screen and (min-width: 1400px) {
  .nextGrid {
    // max-width: 400px;
    background: initial;
  }
}

.contacts{
  @media screen and (max-width:700px){
    font-size: 13px;
  }
}

#circ {
  width: 50%;
  margin: auto;
  display: flex;
  @media screen and(max-width:700px) {
    width: 80%;
  }
  a{
    margin: auto;
    width: 40px;
    height: 40px;
    border: 1px solid $primary;
    border-radius: 50%;
    display: grid;
    justify-content: center;
    align-items: center;
    &:hover{
      i{ color:rgb(204, 10, 10); }
      border: 1px solid #333;
    }
  }
}

//Page Loader

#loader {
  margin: 0 !important;
  padding: 0 !important;
  background: #1d1d1d !important;
  width: 100% !important;
  height: 100vh !important;
}
#ring {
  box-sizing: content-box !important;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  width: 150px !important;
  height: 150px !important;
  background: transparent !important;
  border: 3px solid #3c3c3c !important;
  border-radius: 50% !important;
  text-align: center !important;
  line-height: 150px !important;
  font-family: Candara, Sans-serif !important;
  font-size: 20px !important;
  font-weight: bold !important;
  letter-spacing: 4px !important;
  color: $primary !important;
  text-shadow: 0 0 10px $primary!important;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.7) !important;
}
#ring:before {
  box-sizing: content-box !important;
  content: " " !important;
  position: absolute !important;
  top: -3px !important;
  left: -3px !important;
  width: 100% !important;
  height: 100% !important;
  border: 3px solid transparent !important;
  border-top: 3px solid $primary !important;
  border-right: 3px solid $primary !important;
  border-radius: 50% !important;
  animation: animateCircle 1.5s linear infinite !important;
}
span#spanner {
  display: block !important;
  position: absolute !important;
  top: calc(50% - 2px) !important;
  left: 50% !important;
  width: 50% !important;
  height: 4px !important;
  background: transparent !important;
  transform-origin: left !important;
  animation: sanimate 1.5s linear infinite !important;
}
span#spanner:before {
  content: " " !important;
  position: absolute !important;
  border-radius: 50% !important;
  width: 16px !important;
  height: 16px !important;
  background: $primary !important;
  top: -6px !important;
  right: -8px !important;
  box-shadow: 0 0 20px $primary !important;
}
@keyframes animateCircle {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes sanimate {
  0% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(405deg);
  }
}

.video-container{
  width: 100vw;
  height: 100vh;
  margin: auto;
  max-width: 100%;
}

.shaka-video{
  width: 100%;
  height: 100%;
}