.trending_videos,.new_videos,.search_results{
    width: 95%;
    margin: 0 auto;
    position: relative;
    .heading{
        position: relative;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50vh;
        
        @media screen and (max-width:700px) {
            height: 25vh;
            align-items: flex-end;
        }
        
        h1{
            margin: 5% 0 0% 2%;
            font-weight: 900;
            font-size: 5em;
            position: relative;
            z-index: 1;
            filter: drop-shadow(5px 5px 0 #9e0000);
            font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
            @media screen and (max-width:700px) {
                filter: drop-shadow(4px 4px 0 #9e0000);
                font-size: 3em;
                padding-bottom: .5em;
            }
        }
        
        img{
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: 0;
            opacity: 1;
            opacity: .7;
        }
    }
    .search_Handler{
        background: #111;
        padding:1em;
        margin: 0em auto 3em auto;
        width: 30%;
        display: flex;
        border: 1px solid #333;
        border-radius: 5em;
        justify-content: space-around;
        align-items: center;
        text-align: center;
        @media screen and (max-width:1024px) {
            width: 50%;
        }
        @media screen and (max-width:700px) {
            width: 95%;
            margin: -1em auto 2em auto;
            padding:0.7em;
        }
        input{
            background: transparent;
            border: none;
            height: 100%;
            flex: 0.7;
            font-weight: 500;
            font-size: 1em;
            outline: none;
            color: whitesmoke;
            @media screen and (max-width:700px) {
                width: 60%;
            }
        }
        i{
            font-size: x-large ;
            color: lightgray;
        }
        .show,.send{
            cursor: pointer;
            &:hover{
                color:crimson;
            }
        }
        
    }
    .results{
        p{
            color: whitesmoke;
            opacity: 0.7;
            padding: 0em 2em;
            font-weight: bolder;
            font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
            font-size: 1.5rem;
            @media screen and (max-width:700px) {
                padding: 0 1em;
                font-size: 1.1rem;
            }
        }
    }
    .items{
        padding: 25px 5vw;
        -moz-box-orient: horizontal;
        -moz-box-direction: normal;
        justify-content: space-evenly;
        -moz-box-align: start;
        align-items: flex-start;
        grid-gap: 1em;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        
        .vitem{
            img{
                width: 100% !important;
            }
        }

        @media screen and (max-width: 2000px) {
            grid-template-columns: 20% 20% 20% 20% 20%;
        }
        @media screen and (max-width: 1600px) {
            grid-template-columns: 25% 25% 25% 25%;
        }
        @media screen and (max-width: 1023px) {
            grid-template-columns: 50% 50%;
            padding: .5em;
            grid-template-columns: 1fr 1fr;
            .secondary{
                width: 100% !important;
            }
        }
        
    }
    .loading{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        justify-content: space-between;
        align-items: center;
    }
    .error{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 10em;
        box-sizing: border-box;
        @media screen and(max-width:700px) {
            padding: 0;
            margin-top: 2em;
            flex-flow: column;
        }
        .image{
            flex: 0.5;
            padding: 5em;
            @media screen and(max-width:700px) {
                padding: 3em;
            }
        }
        .text{
            flex: 0.5;
            text-align: center;
            @media screen and(max-width:700px) {
                flex: 1;
            }
        }
    }
    .placeholder{
        width: 100%;
        text-align: center;
        font-size: 15rem;
        opacity: 0.2;
        @media screen  and (max-width:700px){
            font-size: 10rem;
        }
        @media screen  and (min-width:2000px){
            font-size: 30rem;
        }
    }
}

.search_results{
    margin: 5em auto;
    padding: 3em 0;
    @media screen and (max-width:700px) {
        width: 90%;
        margin: 5em auto 0em auto;
    }
}