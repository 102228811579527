@import "../variables";

.sticky-navbar{
    background: linear-gradient(black,transparent);
    
    @media screen and (min-width:1024px) {
        width: 100%;
        padding: 0 2%;
    }
    .header-one{
        @include flexed();
        width: 100%;
        ul{
            list-style: none;
            transform: translate(0%,20%);
            transition: $trans;
            @media screen and (min-width:800px) {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            li{
                transition: $trans;
                position: relative;
                margin-left: .5em;
                a{
                    color: white;
                    font-size: 15px;
                    font-weight: lighter;
                    @include flexed();
                    transition: $trans;
                    .icon{
                        transform: translateX(-50%);
                        transition: $trans;
                    }
                    
                    span{
                        text-shadow: 1px 1px 0 #111;
                        transition: $trans;
                    }
                    
                    span::before{
                        content: "";
                        position: absolute;
                        width: 0%;
                        right: 20%;
                        bottom: 0%;
                        height: 5%;
                        transition: all .3s ease-out;
                        transform: translate(-50%,-50%);
                        background: $primary;
                        z-index: -1;
                    }

                    &:hover{
                        span::before{
                            content: "";
                            position: absolute;
                            width: 50%;
                            right: 20%;
                            bottom: 0%;
                            height: 5%;
                            transform: translate(-50%,-50%);
                            background: $primary;
                            z-index: -1;
                        }
                    }
                    @media screen and (max-width:1600px) {
                        font-size: 14px;
                    }
                    @media screen and (max-width:1400px) {
                        font-size: 12px;
                    }
                }
                
                .active{
                    color: white;
                    font-weight: bold;
                    .icon{
                        color: $primary;
                        transition: $trans;
                        mix-blend-mode: difference;
                    }
                    span{
                        text-shadow: 1px 1px 0 #111;
                        transition: $trans;
                    }
                    span::before{
                        content: "";
                        position: absolute;
                        width: 20%;
                        right: 20%;
                        bottom: 0%;
                        height: 5%;
                        transition: all .5s ease-out;
                        transform: translate(-50%,-50%);
                        background: $primary;
                        z-index: -1;
                    }
                    border-radius: $border-radius;
                    @media screen and (max-width:800px) {
                        background: none;
                        text-shadow: 1px 1px 0px $primary;
                    }
                }
            }
        }
        form{
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: relative;
            transform: translateX(-13%);
            ul{
                list-style: none;
            }
            .status-icon{
                cursor: pointer;
                transform: translate(-50%,-15%);
            }
            .avatar{
                transform: translate(-60%,-10%)
            }
            .input-group {
                overflow: hidden;
                border-radius: $border-radius;
            }
        }
    }
    
    .logo{
        transform: none;
        @include logo();
        @media screen and (max-width:800px) {
            width: 60%;
            transform: translateY(-23%);   
        }
    }

    img{
        transform: translateX(-50%);
    }

    button{
        @media screen and (max-width:800px) {
            outline: none;
            width: 7%;
            background: transparent;
        }
        @media screen and (max-width:700px) {
            outline: none;
            width: 15%;
            background: transparent;
        }
    }
    .bars{
        color: $primary;
    }
}

.dropdown-item{
    font-family: "Sarala-Regular", sans-serif !important;
    display: flex;
    align-items: center;
    font-size: 15px;
    justify-content: space-around;
    i{
        font-size: large;
    }
}

.headerWithBg{
    background: none;
    .header-one{
        transition: $trans;
        border-radius: $border-radius+50;
        backdrop-filter: blur(20px);
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
        padding-left: .5%;
        background: rgba(140, 140, 140, 0.1);
    }
  }


.navbar-collapse{
    @media screen and (max-width:800px) {
        position: relative;
        width: 100%;
        border: 1px solid $secondary;
        border-radius: $border-radius;
        box-shadow: 0px 0px 50px 0px black;
        background: $secondary;
    }
    ul{
        li{
            justify-content: flex-start;
            a{
                color: lightgrey;
                text-shadow: none;
                &:hover{
                    color: white;
                }
            }
            .active{
                color: white;
                font-weight: bold;
                background: $primary;
                border-radius: $border-radius;
                @media screen and (max-width:800px) {
                    background: none;
                    text-shadow: 1px 1px 0px $primary;
                }
            }
        }
    }
}

.dropdown{
    position: absolute;
    .dropdown-menu{
        position: absolute;
        left: -10;
    }
}   

.navbar-toggler{
    background: $primary;
    margin-top: -7%;
    outline: none;
    i { color: white;font-weight: smaller }
}

@keyframes trans{
    0%{
        left: 110%;
    }
    100%{
        left: 150%;
    }
}


@keyframes wave{
    0%{
        background-position: right;
    }
    50%{
        background-position: left;
    }
    100%{
        background-position: right;
    }
}

@keyframes presence {
    0%{
        top: -50%;
    }
    100%{
        top: 50%;
    }
}

@keyframes presenceLeft {
    0%{
        left: -100%;
    }
    100%{
        left: 0;
    }
}


@keyframes exit {
    0%{
        top: 50%;
    }
    100%{
            top: -50%;
    }
}


.SpeechAi{
    position:fixed;
    width: 45%;
    height: 50%;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 3em;
    backdrop-filter: blur(40px);
    background-clip: padding-box;
    border: solid 2px rgba(211, 211, 211, 0.2);
    overflow: hidden;
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    align-items: center;
    animation: presence 2s cubic-bezier(.48,0,.36,1.48);
    -webkit-backdrop-filter: blur(40px);
    @media screen and(max-width:1024px){
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        transform: none;
        border-radius: 0;
        animation: presence 1s cubic-bezier(.48,0,.36,1.48);
        padding-top: 5rem;
    }
    .closer{
        position: absolute;
        top: 10%;
        left:90%;
        transform: translate(-90%,-10%);
        border: 1px solid white;
        border-style: dashed;
        border-radius: 50%;
        padding: 7px 10px;
        cursor: pointer;
        text-shadow: 1px 1px 0px #e50914;
        z-index: 2;
        @media screen and(max-width:1024px){
            top:20%
        }
    }
    img{
        opacity: 0.1;
        width: 200%;
        height: 200%;
        position: absolute;
        top: 90%;
        transform: translate(-100%,-70%);
        animation: trans 5s linear infinite;
        @media screen and(max-width:1024px){
            top: 100%;
        }
    }
    h1{
        font-size: 60px;
        font-family: 'Lobster', cursive;
        opacity: 0.8;
        @media screen and(min-width:1900px){
            font-size: 70px;
        }

        @media screen and(max-width:700px){
            font-size: 45px;
        }
    }
    h2{
        font-family: 'Courgette', cursive;
        opacity: 0.9;

        @media screen and(max-width:700px){
            font-size: 20px;
        }
        i{
            text-shadow: 1px 1px 0px #e50914;
        }
        span{
            quotes: "“" "”" "‘" "’";
            &::before {
                content: open-quote;
                color: whitesmoke
            }
            &::after {
                content: close-quote;
                color: whitesmoke
            }
            strong{
                background: #555;
                padding: 0 10px;
                border-radius: 3em;
            }
        }
    }
    p{
        letter-spacing: 2px;
        color: whitesmoke;
        font-weight: lighter;
        font-family: 'Quicksand', sans-serif;
        z-index: 1;
        position: relative;
        animation: presenceLeft 2s cubic-bezier(.48,0,.36,1.48);
        i{
            color: green;
            text-shadow: 1px 1px 0px #333;
        }
        strong{
            text-shadow: 1px 1px 0px #e50914bb;
        }
    }
}

.menu{
    height: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-around;
    color: lightgrey;
    @media screen and (min-width:1024px) {
        display: none;
    }
    li{
        list-style: none;
        width: 100%;
        padding: 3%;
        margin: 3% 0;
        font-weight: 600;
        font-size: 1em;
        display: flex;
        align-items: center;
        justify-content: space-between;
        a{
            text-decoration: none;
        }
        i{
            margin: 0 15%;
            font-size: larger;
        }
    }
}
