.offline{
    padding: 0 7em;
    height: 100vh;
    background: url('../../assets/images/2.jpg')no-repeat;
    background-size: cover;
    background-position: bottom;
    font-family: $fontFamily;


    @media screen and (max-width:800px) {
        flex-flow: column-reverse;
        justify-content: space-evenly;
        padding: 0;
        width: 100%;
    }

    .left{
        position: absolute;
        left: 50%;
        top: 80%;
        transform: translate(-50%,-50%);
        text-align: center;
        width: 40%;

        @media screen and (min-width:2000px) {
            width: 30%;
        }
        @media screen and (max-width:1400px) {
            width: 50%;
        }
        @media screen and (max-width:700px) {
            width: 90%;
        }
        .fa-plug{
            position: absolute;
            left: 0;
            top: 0;
            font-size: 10em;
            color: grey;
            opacity: .07;
            z-index: 0;
            filter: drop-shadow(2px 2px 0px $primary);
            @media screen and (max-width:1400px) {
                opacity: .2;
            }
            @media screen and (max-width:800px) {
                font-size: 8em;
                opacity: .2;
                left: 5%;
                top: -80%;
            }
        }
        h1{
            font-size: 50px;
            color: $text;
            font-weight: bold;
            letter-spacing: 1px;
            @media screen and (max-width:800px) {
                font-size: 2.5em;
                color: #999;
            }
        }
        p{
            font-size: 30px;
            text-align: center;
            color: $text/1.5;
            letter-spacing: 1px;
            @media screen and (max-width:800px) {
                font-size: 1.3em;
                padding: 0 1.5em;
                text-align: center;
            }
        }
        button{
            border: 1px solid $secondary;
            background-color: $primary;
            font-size: 1.2em;
            text-transform: capitalize;
            color: $text;
            font-weight: bold;
            font-size: 20px;
            width: 25%;
            display: flex;
            align-items: center;
            padding: 0;
            justify-content: space-between;
            overflow: hidden;
            padding: .1em .2em;
            transition: $trans;
            border-radius: 3em;
            outline: none;
            
            @media screen and (min-width:800px) {
                margin: 1em auto;
            }
            @media screen and (max-width:800px) {
                margin: 1em auto;
                width: 35%;
            }
            span{
                flex: 0.8;
                text-align: center;
                padding: .3em 0.2em;
                letter-spacing: 1px;
            }
            strong{
                background: $text;
                height: 100%;
                display: flex;
                align-items: center;
                border-radius: 3em;
                @media screen and (min-width:800px) {
                    padding: .5em .1em;
                }
                @media screen and (max-width:800px) {
                    margin: 0;
                    padding: .5em .1em;
                }
            }
            i{
                margin: 0 .5em;
                transition: $trans;
                color: $primary;
            }
            
            .fa-spinner{
                transform: none;
                animation: spin 2s linear infinite;
            }

            @media screen and (max-width:800px) {
                font-size: 1em;
                padding: .15em .2em;
            }

            &:hover{
                background: $primary/1.5;
                i{
                    transform: rotate(50deg);
                }
            }
        }
    }
    .globe{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        object-fit: cover;
        width: 100%;
        opacity: .1;
        @media screen and (min-width:800px) {
          display: none;
        }
    }
}

@keyframes spin{
    0%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(360deg);
    }
}

@keyframes anim{
    0%{
        background: $primary/2.5;
    }
    50%{
        background: $primary/3;
    }
    100%{
        background: $primary/2.5;
    }
}
