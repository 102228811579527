$primary: #e50914;
$secondary:#1a1a1a;

$fontFamily:"Sarala-Regular", sans-serif !important;

$text:white;
$muted-text:rgba(255, 255, 255, 0.3);
$input-bg:whitesmoke;

$border-radius: 5px;
$br: $border-radius;
$btn-border-radius-lg: 2px;
$btn-border-radius-sm: 2px;
$trans: all .5s;

$font-bg: 20px;
$font-xl: 30px;

$navbar-base-height: 56px;
$sidebar-base-width: 200px;
$sidebar-collapsed-width: 90px;
$sticky-footer-height: 50px;

@font-face {
    font-family: "Sarala-Regular";
    src: url(../assets/fonts/Sarala-Regular.ttf);
}

@font-face {
    font-family: "Sarala-Bold";
    src: url(../assets/fonts/Sarala-Bold.ttf);
}


@mixin centered {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
}

@mixin flexed {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@mixin btn {
    border: none;
    border-radius: $border-radius;
    background: $primary/1;
    text-transform: capitalize;
    padding: .5em 1.5em;
    color: $text;
    font-size: 20px;
    font-weight:bold;
    transition: $trans;
    outline: none;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    img{
        width: 12%;
        transition: $trans;
    }
    i{
         margin: 0 .5em;
    }
    &:hover{
        background: $primary/1.5;
        img{
            transform: translateX(30%);
        }
    }
    @media screen and (max-width:800px) {
        font-size: 13px;
    }
}

@mixin logo {
    background: none;
    color: $primary;
    text-transform: uppercase;
    font-family: 'Sarala-Bold',sans-serif;
    font-size: 35px;
    @media screen and (max-width:800px) {
        font-size: 25px;
        font-weight: bolder;
    }
}
