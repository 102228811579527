@import "../variables";

.specific-parent{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    background: linear-gradient($secondary 40%,black ), $secondary;

    .specific-loader{
        flex: 1;
        position: relative;
        min-height: 80vh;
    }
    
    .specific-container{
        width: 80%;
        margin: 0 auto;
        max-width: 1600px;
        margin-top: 2em;

        @media screen and (max-width:800px) {
            width:95%;
            position: relative;
            margin-top: 0;
        }

        section{
            display: flex;
            justify-content: space-between;
            position: relative;


            @media screen and (max-width:800px) {
                flex-direction: column;
                &:nth-child(1){
                    height: auto;
                }
            }

            h6{
                position: relative;
                z-index: 1;
            }

            .blury-wrapper{
                position: absolute;
                width: 100vw;
                height: 125%;
                z-index: 0;
                top: -25%;
                left: 50%;
                transform: translateX(-50%);
                overflow: hidden;
                box-shadow: none;

                @media screen and (max-width:800px) {
                    display: none;
                }

                &::after{
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(180deg, rgba(0,0,0,.0), transparent, $secondary);
                    z-index: 1;
                }

                img{
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: top;
                    filter: blur(5px);
                    z-index: 0;
                    opacity: .1;
                }
            }

            .back{
                @include flexed();
                justify-content: flex-start;
                align-items: center;
                color: grey;
                font-weight: 550;
                letter-spacing: 1px;
                cursor: pointer;
                margin-bottom: auto;
                margin-top: 1em;
                width: 100%;
                font-size: 1em;
                transition:$trans;

                span{
                    margin-left: .3em;
                    transition:all .2s;
                }
                
                @media screen and (max-width:800px) {
                    margin: 1em 0;
                    justify-content: space-between;
                    width: 90%;
                    margin: 1em auto;
                }

                &:hover{
                    color: whitesmoke;
                }
            }

            .Tall-avatar{
                flex: .3;
                z-index: 1;
                overflow: hidden;
                border-radius: 3em;
                position: relative;
                background: $secondary;
                border: 5px solid $secondary;
                box-shadow: 0px 0px 1em rgba(0, 0, 0, .1);

                .mobile-play-btn{
                    position: absolute;
                    left: 50%;
                    top: 40%;
                    color: $primary/1.2;
                    filter: drop-shadow(0 0 1em rgba(0, 0, 0, 1));
                    transform: translate(-50%,-50%);
                }
                
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: top left;
                    border-radius: 2.5em;

                    @media screen and (max-width:800px) {
                        border-radius: 0;
                    }
                }

                @media screen and (max-width:1600px) {
                    flex: .35;
                }
                
                @media screen and (max-width:800px) {
                    border: 0;
                    border-radius: 0;
                    position: fixed;
                    top: 8%;
                    left: 0;
                    width: 100%;   
                    z-index: 0;
                }
            }

            .content{
                flex: 0.65;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                padding-bottom: 1em;
                position: relative;
                z-index: 1;
                
                @media screen and (max-width:1600px) {
                    flex: .6;
                }

                @media screen and (max-width:800px) {
                    position: relative;
                    margin-top: 60vh;
                    width: 110%;
                    background: $secondary;
                    left: -5%;
                    padding: 10%;
                    &::before{
                        content: "  ";
                        position: absolute;
                        backdrop-filter: blur(50px);
                        width: 110%;
                        height: 100%;
                        top: -5%;
                        left: -5%;
                        z-index: 0;
                        border-top: 5px solid $secondary;
                        box-shadow: 0 -1em 1em rgba(0, 0, 0, 0.3);
                        border-top-left-radius: 4em;
                        border-top-right-radius: 4em;
                    }
                }
                
                h1{
                    font-weight: bold;
                    text-transform: capitalize;
                    filter: drop-shadow(0 0 2px black);
                    letter-spacing: 1px;
                    z-index: 1;
                    position: relative;
                }

                p{
                    color:$text/1.3;
                    z-index: 1;
                    position: relative;
                }

                .header{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    text-transform: capitalize;
                    width: 95%;
                    margin-top: 1em;
                    z-index: 1;
                    position: relative;
                    
                    @media screen and (max-width:800px) {
                        width: 100%;
                    }

                    h1{
                        margin: 0;
                        font-size: 3em;
                        flex: 1;
                        max-width: 80%;
                        @media screen and (max-width:800px) {
                            font-weight: bold;
                            flex: 1;
                        }
                    }

                    span{
                        color:grey;
                        
                        @media screen and (max-width:800px) {
                            display: none;
                        }
    
                        strong{
                            color: $text;
                        }
                    }
                }
                .features{
                    color: grey;
                    text-transform: capitalize;
                    z-index: 1;
                    margin: .5em 0;
                    position: relative;
                }
                .subtitles{
                    color: lightgrey;
                    position: relative;
                    z-index: 1;
                    span{
                        color:$muted-text;
                        text-decoration: line-through;
                    }
                }
                h4{
                    color: $text/1.8;
                    z-index: 1;
                    position: relative;
                    span{
                        font-size: 1em;
                        font-weight: 300;
                        color: lightgrey;
                    }
                }
                .cast{
                    text-transform: capitalize;
                    font-weight: lighter;
                    color:$text/2;
                }
                .btns{
                    @include flexed();
                    justify-content: flex-start;
                    margin-top: 3em;
                    position: relative;
                    
                    @media screen and (max-width:1600px) {
                        margin-top: 1em;
                    }

                    @media screen and (max-width:800px) {
                        justify-content: center;
                        display: none;
                    }
                    button{
                        @include btn();
                        border-radius:5em;

                        .icon{
                            background: #333;
                            padding: 10px;
                            border-radius: 50%;
                            color: $primary;
                            font-size: 50px;
                        }
                        
                        &:hover{
                            .icon{
                                background: #2a2a2a;
                            }
                        }

                        &:first-child{
                            .icon2{
                                @media screen and (max-width:800px) {
                                    display: none;
                                }
                            }
                            padding: .5em 1em;
                            @media screen and (max-width:800px) {
                                padding: .2em 1em;
                            }
                            font-size: 1.2em !important;
                        }

                        &:last-child{
                            margin-left:2em;
                            padding: 0;
                            text-transform: uppercase;
                            font-weight: 500;
                            background: transparent;
                            position: relative;
                            overflow: hidden;
                            z-index: 1;
                        }
                    }
                    div{
                        position: absolute;
                        width: 2%;
                        border-radius:2em;
                        height: 100%;
                        right: 0;
                        overflow: hidden;
                        border: 1px solid #2a2a2a;
                        @media screen and (max-width:800px) {
                            display: none;
                        }
                        span{
                            border-radius:2em;
                            position: absolute;
                            width: 80%;
                            margin-left: 8%;
                            height: 20%;
                            left: 0%;
                            height: 20%;
                            background: $primary;
                            animation: scroller 2s infinite ease-out ;
                        }
                    }
                }
            }

            &:last-child{
                height: 80vh;
                max-height: 700px;
                margin: 10em auto 3em auto;
                max-width: 1200px;
                max-height: 650px;
                @include flexed();
                align-items: initial;
                @media screen and (max-width:800px) {
                    display: none;
                }
                .controls{
                    display: flex;
                    flex-direction: column;
                    padding-top: 2em;
                    .muter{
                        border: 1px solid $text;
                        padding: .5em;
                        border-radius: 100%;
                        margin: 1em 0;
                        cursor: pointer;
                        color:$text;
                        transition: $trans;
                        cursor:pointer;
                        &:hover{
                            border: 1px solid grey;
                            color: grey;
                        }
                        &:last-child:hover{
                            transform: rotate(-30deg);
                        }
                    }
                }
                .trailer{
                    flex:.95;
                    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
                    border-radius: 2em;
                    position: relative;
                    background: rgba(0, 0, 0, 0.3);
                    &::before{
                        content: " ";
                        position: absolute;
                        width: 30%;
                        height: 55%;
                        left: 40%;
                        top: -10%;
                        z-index: -1;
                        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
                        border-radius: 100%;
                        background: linear-gradient(to bottom left,$primary/2 10%,transparent);
                    }
                    &::after{
                        content: " ";
                        position: absolute;
                        width: 30%;
                        height: 55%;
                        right: -5%;
                        bottom: 0%;
                        border-radius: 100%;
                        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
                        z-index: -1;
                        background: linear-gradient(to bottom right,$primary/2 10%,transparent);
                    }
                    section{
                        position: absolute;
                        width: 30%;
                        height: 55%;
                        left: -10%;
                        bottom: -10%;
                        border-radius: 100%;
                        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
                        z-index: -1;
                        background: linear-gradient(to bottom right,$primary/2 10%,transparent);
                    }
                    h3{
                        text-transform: capitalize;
                        margin-left: 2em;
                        margin-top: 1.5em;
                        font-size: 2em;
                        font-weight: bold;
                        filter: drop-shadow(0px 0px 2px $primary/2);
                        z-index: 2;
                        position: relative;
                    }
                    video{
                        backdrop-filter: blur(10px);
                        box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
                        background: transparent;
                        position: absolute;
                        object-fit: cover;
                        z-index: 1;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        border-radius: 2em;
                        cursor: none;
                        -webkit-backdrop-filter: blur(10px);
                    }
                }
            }
        }
    }

}

.specific-footer{
    position: relative;
    z-index: 1;
    @media screen and (max-width:800px) {
        background: linear-gradient($secondary,black);   
    }
}
