// =============== Buttons ===============
.btn-transparent {
  background: rgba(51, 51, 51, 0.4);
  color: white;
  @media screen and (max-width:700px){
    font-size: 15px;
  }
  &:hover {
    background: rgba(255, 255, 255, 0.7);
    color: black;
  }
}
.btn-circle {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  text-align: center;
  font-size: 18px;
  line-height: 22px;
}

.btn-circle-sm {
  width: 25px;
  height: 25px;
  padding: 4px 0px;
  border-radius: 15px;
  text-align: center;
  line-height: 15px;
  font-size: 13px;
}

.btn-circle-lg {
  width: 55px;
  height: 55px;
  line-height: 55px;
  font-size: 1.1rem;
}

.btn-circle-xl {
  width: 70px;
  height: 70px;
  line-height: 70px;
  font-size: 1.3rem;
}

.start{
  background: #333;
  backdrop-filter: blur(50px);
  transition: 0.5s;
  border: none;
  color: white;
  font-weight: bold;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 35%;
  position: relative;
  z-index: 0;
  transform-style: preserve-3d;
  @media screen and (max-width:1024px){
    width: 40%;
  }
  @media screen and (max-width:800px){
    margin: auto;
  }
  @media screen and (max-width:700px){
    width: 60%;
    margin: auto;
  }
  img{
    width: 10px;
    transform: rotate(-90deg);
  }
  &:hover{
    background: #333;
    color: white;
    border-radius: 2px;
    &::after{
      left: -0.5%;
      top: 3%;
      width: 100%;
    }
  }
  &::before{
    content: "";
    position: absolute;
    z-index: -2;
    top: 20%;
    left: -3%;
    height: 100%;
    width: 100%;
    border: 2px solid crimson;
    border-radius: 2px;
    transform: translateZ(-1px);
    display: none;
    transition: 0.5s;
    @media screen and (max-width:800px){
      width: 106%;
    }
  }
}
