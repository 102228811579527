.footer{
    width: 60%;
    max-width: 1300px;
    display: flex;
    justify-content: space-between;
    align-items:flex-start;
    margin: 5em auto 2em auto;

    @media screen and (max-width:1600px) {
        width:70%
    }

    @media screen and (max-width:800px) {
        width: 80%;
    }
    @media screen and (max-width:700px) {
        width: 90%;
        display: grid;
        margin: 5em 0 2em auto;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        justify-content: center;
        grid-gap: 1em ;
    }
    .left{
        margin-top: -1em;
        @media screen and (max-width:800px) {
            margin-top: 0;
        }
        .logo{
            @include logo();
            padding: 0;
            transform: translateY(40%);
            @media screen and (max-width:800px) {
                transform: translateY(0%);
                font-size: 25px;
            }
        }
        p{
            color: $muted-text;
            transform: translateY(-10%);
            @media screen and (max-width:800px) {
                transform: translateY(-50%);
            }
        }
    }
    section{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        a{
            color: $muted-text;
            margin: .3em 0;
            transition: $trans;
            &:hover{
                text-decoration: none;
                color: $text;
            }
        }

        .ig:hover{
            color: orange;
            font-weight: bold;
        }
        .fb:hover{
            font-weight: bold;
            color: dodgerblue;
        }
        .yt:hover{
            font-weight: bold;
            color: $primary;
        }
    }
}

hr{
    background: $muted-text/2;
    height: .1px;
}

.footer_2{
    width: 60%;
    max-width: 1300px;
    margin:0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width:1600px) {
        width:70%;
    }
    @media screen and (max-width:800px) {
        width: 80%;
    }

    p{
        color: $muted-text;
        font-size: 25px;
        letter-spacing: 1px;
        padding-top: .5em;
        @media screen and (max-width:800px) {
            font-size: 18px;
            padding-top: 0;
        }
    }

    div{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 10%;
        margin: -1.5% 0% 0 0;
        @media screen and (max-width:800px) {
            width: 20%;
        }
        @media screen and (max-width:700px) {
            width: 30%;
            margin: -1em 0 0 0;
        }
        img{
            width: 25%;
            cursor: pointer;
            &:nth-child(2){
                width: 30%;
            }
            &:nth-child(3){
                width: 21%;
            }
        }
    }
}