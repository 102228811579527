// =============== CAROUSEL ===============
@keyframes fader{
  from{
    opacity: 0 ;
  }
  to{
    opacity: 1 ;
  }
}

.carousel-container,.Previews-container{
  display: flex;
  flex-flow: column;
  align-items: left;
  width: 97%;
  z-index: 1;
  position: relative;
  padding: 15px 0;
  margin:0 auto;
  overflow: hidden;

  @media screen and (max-width:700px){
    padding: 5px ;
  }

  .header{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    margin: .5em 0;
    font-weight: bolder;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

    @media screen and (max-width:1024px) {
      div{
        display: none;
      }
    }

    @media screen and (max-width:700px){
      padding: 0;
      margin: 0;
      margin-bottom: -.3em;
    }

    a { 
      text-decoration: none;
      color: white; 
      font-size: 23px;
      position: relative;
      z-index: 2;
      text-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
      span{
        width: 100%;
      }
      i{
        font-size: 18px;
        margin-left: .5em;
        transition: .5s;
        @media screen and (max-width:1600px) {
          font-size: 15px;
        }
      }
      &:hover{
        i{
          margin-left: 1.5em;
        }
      }
      @media screen and (max-width:700px) {
        font-size: 18px;
        i{
        margin-top: .4em;
        font-size: 12px;
        }
      }
    }

    div{
      z-index: 1;
      button{
        border: none;
        margin: 0 auto;
        border-radius: 1px;
        color: lightgrey;
        background: #333;
        box-shadow: 0 0 2px 0 grey;
        border-radius: 3px;
        outline: none;
        &:first-child{
          margin-right: 5px;
        } 
        @media screen and (max-width:700px) {
          padding:0px 4px;
        }
      }
    }
  }

  section{
    position: relative;
    .carousel-wrapper{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-right: .5%;
      position: relative;
      transition: $trans;

      button{
        border: none;
        padding:0 1em;
        position: absolute;
        height:100%;
        z-index: 3;
        outline: none;
        animation: fader 1s forwards;
        @media screen and (max-width:700px) {
          display: none;
        }
      }


      .leftbutton{
        left: 0;
        z-index: 3;
        opacity: .4 !important;
        background: linear-gradient(to right,$secondary/2,$secondary);
        box-shadow: 4px 0px 5px rgba(0, 0, 0, 0.3);
        &:hover{
          opacity: .9 !important;
        }
        img{
          transform: rotate(180deg);
        }
      }

      .rightbutton{
        right: 0;
        box-shadow: -4px 0px 5px rgba(0, 0, 0, 0.3);
        background: linear-gradient( to left,$secondary/2,$secondary);
      }

    }
    .carousel,.Previews{
      width:100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .vitem:first-child,.v2item:first-child{
        &:hover{
          transform: translate(20%,-8%) scale(1.3);
        }
      }
      .vitem:last-child,.v2item:last-child{
        &:hover{
          transform: translate(-20%,-8%) scale(1.3);
        }
      }
      @media screen and(max-width:1024px){
        overflow-x: scroll;
        scroll-behavior: smooth;
        &::-webkit-scrollbar{
          display: none;
        }
        .items{
          display: flex;
          width: auto;
        }
      }
      @media screen  and (max-width:700px){
        padding: 1em 0;
      }
    }
  }
}

@keyframes fromright{
  0%{
      right: -3%;
  }
  100%{
      right: 0;
  }
}


@keyframes fromleft{
  0%{
      left: -3%;
  }
  100%{
      left: 0;
  }
}

@keyframes outleft{
  0%{
      right: 0%;
  }
  100%{
      right: -3%;
  }
}


@keyframes outright{
  0%{
      left: 0%;
  }
  100%{
      left: -3%;
  }
}