@import '../variables';

.video-section {
    width: 96.5%;
    padding: 0;
    border-radius: $border-radius;
    overflow: hidden;
    margin: 1% auto;
    max-height: 100%;
    display: flex;
    justify-content: space-between;
    background: black;
    box-shadow: 0px 0px 20px rgba(255,255,255,.02);
    height: 500px;

    @media screen and (max-width:1600px) {
        height: 400px;
    }

    @media screen and (max-width:1400px) {
        height: 300px;
    }

    @media screen and (max-width:800px) {
        display: none;
    }

    .left{
        flex: .5;
        display: flex;
        justify-content: space-between;
        position: relative;
        border-radius: $border-radius;
        &:before{
            content: " ";
            position: absolute;
            width: 100%;
            height: 100%;
            left: 60%;
            background: linear-gradient(to right,black,black,#1a1a1a00);
            z-index: 0;
            @media screen and (max-width:800px) {
                left: 0;
                top: -100%;
                background: linear-gradient(to top,black,black,#1a1a1a00);
            }
        }
        img{
            border: none;
            outline: none;
        }
        .overlay{
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: 1;
            filter: blur(100px);
        }
        .avatar{
            flex: 1;
            padding: .5em;
            @media screen and (min-width:2000px) {
                flex: .7;
            }
            img{
                width: 100%;
                height: 100%;
                margin: auto;
                border: none;
                outline: none;
                filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.5));
                position: relative;
                border-radius: $border-radius;
                z-index: 1;
            }
        }
        .content{
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            position: relative;
            z-index: 1;
            padding-right: 10%;
            filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.5));
            .btns{
                margin-bottom: -20%;
                margin-left: -10%;

                @media screen and (max-width:1400px) {
                    margin-bottom: -10%;
                }
            }

            @media screen and (min-width:1400px) {
                padding-left: 10%;
            }

            @media screen and (max-width:1400px) {
                padding-right: 0%;
                flex: 0.6;
            }

            @media screen and (max-width:800px) {
                flex: 1;
                align-items: center;
                transform: translateY(-10%);
            }
        }
    }
    .right{
        flex:.5;
        img{
            width: 100%;
        }
        video{
            width: 110%;
        }
    }
}